import { Layout, Menu, Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import { useQuery } from 'react-query';
import { BookOutlined } from '@ant-design/icons';

import { DashboardEntries, AgentRolEntries } from '@/share/SystemRoutes';
import logo from '@/assets/img/l_4.png';
import Header from '@/components/dashboard/Header';
import APIService from '@/services/API';
import { useAuth } from '@/hooks/auth.hook';

const { Content, Footer, Sider } = Layout;

type Entry = {
  key: string;
  icon?: React.ReactNode;
  label: string;
  children?: Entry[];
};

const DashboardScreen: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { rol } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // Fetch catalogs
  const { data: catalogs } = useQuery('catalogs', () =>
    APIService.getCatalogs({ $limit: '50' }),
  );
  const targetMenu = rol == 'agent' ? AgentRolEntries : DashboardEntries;

  const menuEntries: Entry[] = [
    ...targetMenu.map((row) => ({
      key: row.path,
      icon: row.icon,
      label: t(row.title || ''),
      ...(row?.children?.length && {
        children: row.children.map((child) => ({
          key: child.path,
          icon: child.icon,
          label: t(child.title),
        })),
      }),
    })),
  ];

  if (rol == 'admin') {
    menuEntries.push({
      key: 'catalogs-menu',
      label: t('catalogs.title'),
      icon: <BookOutlined />,
      children:
        catalogs?.data?.map((row) => {
          const translation = row.configurations.find((row) => row.language === language);
          return {
            key: `/dashboard/catalog/${row._id}`,
            label: translation?.name || '',
          };
        }) || [],
    });
  }

  function parsePath() {
    if (pathname.includes('/dashboard/properties')) {
      return '/dashboard/properties';
    }
    if (pathname.includes('/dashboard/agent')) {
      return '/dashboard/agents';
    }
    return pathname;
  }

  return (
    <Layout className="dashboard fadeIn">
      <Sider breakpoint="lg" collapsedWidth="0">
        <div className="logo">
          <img src={logo} alt="Real state" />
        </div>
        <Menu
          theme="dark"
          mode="vertical"
          onSelect={(e) => navigate(e.key)}
          selectedKeys={[parsePath()]}
          items={menuEntries}
        />
      </Sider>
      <Layout>
        <Header />
        <Content style={{ padding: 24, overflowY: 'auto' }}>
          <Outlet />
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Real State Dashboard &copy; {new Date().getFullYear()} Created by
          <Button
            type="link"
            target="_blank"
            href="https://obsidiansoft.io/"
            style={{ paddingLeft: 5, paddingRight: 5 }}
          >
            ObsidianSoft
          </Button>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default DashboardScreen;
