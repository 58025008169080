import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Row, Col, Avatar, Layout, Dropdown, Select, Modal } from 'antd';
import { useEffect } from 'react';
import FlagIcon from '../ui/FlagIcon';
// Hooks
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/hooks/app.hook';
import { useAuth } from '@/hooks/auth.hook';
// Actions
import { logout } from '@/redux/reducers/auth';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const nav = useNavigate();
  const items = [
    {
      key: 'profile',
      label: (
        <button
          style={{
            minHeight: '40px',
          }}
          onClick={() => nav('/dashboard/profile')}
          className="btn-link"
        >
          {t('header.profile')}
        </button>
      ),
      icon: <UserOutlined />,
    },
    {
      key: 'logout',
      label: (
        <button
          style={{
            minHeight: '40px',
          }}
          onClick={() =>
            Modal.confirm({
              title: t('header.logout'),
              type: 'warn',
              centered: true,
              content: t('header.logoutMessage'),
              onOk: handleLogout,
            })
          }
          className="btn-link"
        >
          {t('header.logout')}
        </button>
      ),
      icon: <LogoutOutlined />,
    },
  ];
  useEffect(() => {
    const language = localStorage.getItem('language') || 'en';
    i18n.changeLanguage(language);
  }, []);
  function handleLogout() {
    dispatch(logout());
  }
  return (
    <Layout.Header className="site-layout-sub-header-background">
      <Row justify="end" align="middle" gutter={[30, 30]}>
        <Col>
          <Select
            value={i18n.language}
            suffixIcon={<FlagIcon code={i18n.language as 'en' | 'es'} />}
            onChange={(value) => {
              localStorage.setItem('language', value);
              i18n.changeLanguage(value);
            }}
            options={[
              {
                label: 'EN',
                value: 'en',
              },
              {
                label: 'ES',
                value: 'es',
              },
            ]}
          />
        </Col>
        <Col>
          <Dropdown menu={{ items }} trigger={['hover']}>
            <Row justify="space-between" gutter={[10, 10]} align="middle">
              <Col>
                <span>{user.full_name}</span>
              </Col>
              <Col>
                <Avatar size={40} icon={<UserOutlined />} src={user.profile} />
              </Col>
            </Row>
          </Dropdown>
        </Col>
      </Row>
    </Layout.Header>
  );
};

export default Header;
