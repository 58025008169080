import {
  ExperimentOutlined,
  HomeOutlined,
  KeyOutlined,
  LockOutlined,
  MailOutlined,
  ReadOutlined,
  SettingOutlined,
  UserOutlined,
  FileSearchOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  BookOutlined,
  DollarOutlined,
  AimOutlined,
  FileOutlined,
  BoxPlotOutlined,
  ShopOutlined,
  BookFilled,
} from '@ant-design/icons';

export const DashboardEntries = [
  {
    title: 'menu.home',
    path: '/dashboard',
    icon: <HomeOutlined />,
  },
  {
    title: 'menu.properties',
    path: '/dashboard/properties',
    icon: <ShopOutlined />,
  },
  {
    title: 'menu.pages',
    path: '/dashboard/pages',
    icon: <BookFilled />,
  },
  {
    title: 'menu.agents',
    path: '/dashboard/agents',
    icon: <UsergroupAddOutlined />,
  },
  {
    title: 'menu.files',
    path: '/dashboard/files',
    icon: <FileSearchOutlined />,
  },
  {
    title: 'menu.security',
    path: '/dashboard/security',
    icon: <LockOutlined />,
    children: [
      {
        title: 'menu.roles',
        path: '/dashboard/roles',
        icon: <UserSwitchOutlined />,
      },
      {
        title: 'menu.user',
        path: '/dashboard/users',
        icon: <UserOutlined />,
      },
      {
        title: 'menu.apiKeys',
        path: '/dashboard/api-keys',
        icon: <KeyOutlined />,
      },
    ],
  },
  {
    title: 'menu.system',
    path: '/dashboard/system',
    icon: <SettingOutlined />,
    children: [
      {
        title: 'menu.labels',
        path: '/dashboard/labels',
        icon: <ReadOutlined />,
      },
      {
        title: 'menu.emailLogs',
        path: '/dashboard/email-logs',
        icon: <MailOutlined />,
      },
      {
        title: 'menu.catalogs',
        path: '/dashboard/catalogs',
        icon: <BookOutlined />,
      },
      {
        title: 'menu.params',
        path: '/dashboard/configuations',
        icon: <ExperimentOutlined />,
      },
      {
        title: 'menu.currencies',
        path: '/dashboard/currencies',
        icon: <DollarOutlined />,
      },
      {
        title: 'menu.units',
        path: '/dashboard/units',
        icon: <AimOutlined />,
      },
      {
        title: 'menu.types',
        path: '/dashboard/types',
        icon: <FileOutlined />,
      },
      {
        title: 'menu.propertyFields',
        path: '/dashboard/property-fields',
        icon: <BoxPlotOutlined />,
      },
    ],
  },
];

export const AgentRolEntries = [
  {
    title: 'menu.home',
    path: '/dashboard',
    icon: <HomeOutlined />,
  },
  {
    title: 'menu.properties',
    path: '/dashboard/properties',
    icon: <ShopOutlined />,
  },
  {
    title: 'menu.pages',
    path: '/dashboard/pages',
    icon: <BookFilled />,
  },
  {
    title: 'menu.files',
    path: '/dashboard/files',
    icon: <FileSearchOutlined />,
    children: [],
  },
];
